import React, { Component } from 'react';
import styles from './photoVerfication.module.css';

class PhotoVerfication extends Component {
	state = {
		fileError: '',
		isFormValid: false,
		imageName: '',
	};

	handleUpload = e => {
		const uploaded = e.target.files[0];
		const imageName = uploaded.name;
		if (uploaded.size < 5000 * 100) {
			this.setState({ isFormValid: true, fileError: '', imageName });
		} else {
			this.setState({
				isFormValid: false,
				fileError: 'The file has to be smaller than 500KB',
				imageName: '',
			});
		}
	};

	render() {
		const { fileError, isFormValid, imageName } = this.state;
		return (
			<form
				name="photoVerification"
				method="POST"
				data-netlify="true"
				netlify-honeypot="bot-field"
				className={styles.form}
			>
				<input type="hidden" name="form-name" value="photoVerification" />
				<input name="bot-field" hidden />

				<input
					className={styles.input}
					type="text"
					name="orderID"
					id="orderID"
					placeholder="Enter your order ID"
					autoComplete="off"
				/>

				<div className={styles.row}>
					<label htmlFor="file" className={styles.label}>
						<div className={styles.uploadButton}>
							{imageName || 'เลือกไฟล์'}
						</div>
						<input
							type="file"
							name="file"
							id="file"
							accept="image/*"
							onChange={this.handleUpload}
							hidden
						/>
					</label>
					<small className={styles.small}>{fileError}</small>

					<button
						className={styles.submitButton}
						type="submit"
						disabled={!isFormValid}
					>
						ยืนยัน
					</button>
				</div>
			</form>
		);
	}
}

export default PhotoVerfication;
