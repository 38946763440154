import React, { Component } from 'react';
import styles from './paymentVerification.module.css';
import PhotoSVG from '../svgs/photoSVG';
import UserSVG from '../svgs/userSVG';
import PhotoVerfication from './photoVerfication';
import VerificationForm from './verificationForm';

class PaymentVerification extends Component {
	state = {
		options: [
			{
				name: 'user',
				description: 'Fill out the form',
				SVG: UserSVG,
				active: false,
				component: <VerificationForm />,
			},
			{
				name: 'photo',
				description: 'Upload a bank receipt as proof',
				SVG: PhotoSVG,
				active: false,
				component: <PhotoVerfication />,
			},
		],
	};

	handleClick = e => {
		const name = e.currentTarget.dataset.itemName;
		const { options } = this.state;
		const index = options.findIndex(option => option.name === name);
		options.forEach(option => (option.active = false));
		options[index].active = true;
		this.setState({ ...options });
	};

	render() {
		const { options } = this.state;
		const activeColStyle = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		};
		const inactiveColStyle = {
			display: 'none',
		};

		return (
			<section className={styles.section}>
				<h2>แจ้งการโอนเงิน</h2>
				<p>
					สำหรับลูกค้าที่โอนเงินแล้ว กรุณากรอกข้อมูลด้านล่างเพื่อเป็นหลักฐาน
				</p>

				<div className={styles.wrapper}>
					{options.map(option => {
						const { name, description, SVG, active, component } = option;
						return (
							<div
								key={name}
								className={!active ? styles.col : styles.colActive}
								onClick={this.handleClick}
								data-item-name={name}
							>
								<div style={!active ? activeColStyle : inactiveColStyle}>
									<SVG width="60" height="60" fill="rgba(131,86,156,.2)" />
									<h5 className={styles.title}>{description}</h5>
								</div>

								<div style={active ? activeColStyle : inactiveColStyle}>
									<SVG width="30" height="30" fill="rgba(131,86,156,.2)" />
									<h5 className={styles.title}>{description}</h5>
									{component}
								</div>
							</div>
						);
					})}
				</div>
			</section>
		);
	}
}
export default PaymentVerification;
