import React, { Component } from 'react';
import styles from './verificationForm.module.css';

class VerificationForm extends Component {
	transferredFrom = [
		'ธนาคารไทยพาณิชย์',
		'ธนาคารกรุงเทพ',
		'ธนาคารกสิกรไทย',
		'ธนาคารกรุงศรี',
		'ธนาคารกรุงไทย',
		'ธนาคารออมสิน',
		'ธนาคารทหารไทย',
		'ธนาคารธนชาต',
		'ธ.ก.ส.',
		'อื่นๆ',
	];

	get today() {
		const d = new Date();
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		const year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}

	render() {
		return (
			<form
				name="userVerification"
				method="POST"
				data-netlify="true"
				netlify-honeypot="bot-field"
				className={styles.form}
				autoComplete="off"
			>
				<input type="hidden" name="form-name" value="userVerification" />
				<input name="bot-field" hidden />

				<div className={styles.row}>
					<input
						className={styles.input}
						type="text"
						name="firstName"
						id="firstName"
						placeholder="ชื่อ"
					/>
					<input
						className={styles.input}
						type="text"
						name="lastName"
						id="lastName"
						placeholder="นามสกุล"
					/>
				</div>

				<div className={styles.row}>
					<input
						className={styles.input}
						type="email"
						name="email"
						id="email"
						placeholder="อีเมลล์"
					/>
					<input
						className={styles.input}
						type="tel"
						name="phone"
						id="phone"
						placeholder="เบอร์โทร"
					/>
				</div>

				<div className={styles.row}>
					<select name="transferredFrom" className={styles.input}>
						{this.transferredFrom.map(account => (
							<option key={account} value={account}>
								{account}
							</option>
						))}
					</select>

					<select name="transferredTo" className={styles.input}>
						<option value="ธนาคารไทยพาณิชย์">ธนาคารไทยพาณิชย์</option>
						<option value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</option>
					</select>
				</div>

				<div className={styles.row}>
					<input
						className={styles.input}
						type="date"
						name="date"
						id="date"
						placeholder="dd.mm.yyyy"
						defaultValue={this.today}
					/>

					<input
						className={styles.input}
						type="number"
						name="amountTransferred"
						id="amountTransferred"
						placeholder="จำนวนเงิน"
					/>
				</div>

				<div>
					<button className={styles.submitButton} type="submit">
						ยืนยัน
					</button>
				</div>
			</form>
		);
	}
}

export default VerificationForm;
