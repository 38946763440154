import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PaymentVerification from '../components/paymentVerification';
import HowToPay from '../components/howToPay';

const PaymentPage = () => {
	return (
		<Layout>
			<SEO title="Payment" />
			<PaymentVerification />
			<HowToPay />
		</Layout>
	);
};

export default PaymentPage;
